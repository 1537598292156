import React, { Component } from 'react'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, ProgressBar, StatusBar } from '@uppy/react'

import './style.css'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/status-bar/dist/style.css'

const TUS_ENDPOINT = 'http://167.99.171.144:1080/files/'

class App extends Component {
  state = {
    files: [],
    uploads: [],
    fileId: '',
    bytesUploaded: 0,
    bytesTotal: 0
  }

  componentWillMount () {
    this.initUppy()
  }

  initUppy = () => {
    this._uppy = new Uppy({autoProceed: true, debug: true})
    this._uppy.use(Tus, {endpoint: TUS_ENDPOINT})
    this._uppy.on('file-added', file => {
      this.setState({ files: [file, ...this.state.files] })
    })
    this._uppy.on('upload-progress', (file, progress) => {
      const { bytesUploaded, bytesTotal } = progress
      this.setState({ fileId: file.id, bytesUploaded, bytesTotal })
    })
    this._uppy.on('upload-success', (file, resp, uploadURL) => {
      let rv = {id: file.id, name: file.name, url: uploadURL}
      this.setState({ uploads: [rv, ...this.state.uploads] })
    })
    this._uppy.on('upload-retry', fileID => {
    })
  }

  dumpState = () => {
    console.log('DUMPING STATE')
    let state = this._uppy.getState()
    console.log(state)
  }

  render () {
    const {files, uploads, fileId, bytesUploaded, bytesTotal} = this.state

    return (
      <div className="font-sans container mx-auto p-4">
        <ul className="p-4 m-4 bg-black list-reset text-xs text-grey-light font-mono absolute pin-b pin-r shadow-lg rounded max-w-xs">
          <li>files: {files.length}</li>
          <li>uploads: {uploads.length}</li>
          <li className="truncate">file id: {fileId}</li>
          <li>bytes uploaded: {bytesUploaded} / {bytesTotal}</li>
        </ul>
        <DragDrop uppy={this._uppy} />
        <ProgressBar uppy={this._uppy} />
        <StatusBar uppy={this._uppy} hideUploadButton={true} hideAfterFinish={false} />
      </div>
    )
  }
}

export default App
